export const XROSS_ENV = 'production';
// export const XROSS_ENV = 'development';
// export const XROSS_ENV = 'staging';

const LIVE_firebaseConfig = {
  apiKey: 'AIzaSyBV92I4MPquUwzD644QDSaVvWbEcruJ0hY',
  authDomain: 'xross-db-prod.firebaseapp.com',
  databaseURL: 'https://xross-db-prod.firebase.com',
  projectId: 'xross-db-prod',
  storageBucket: 'xross-db-prod.appspot.com',
  messagingSenderId: '794992146383',
  appId: '1:794992146383:web:7c666b3a26b284643f04ba',
};

const DEV_firebaseConfig = {
  apiKey: 'AIzaSyCRCJiNcaTu2MU4GQ8SYV17lxD0d87Pd1k',
  authDomain: 'xross-db-test.firebaseapp.com',
  databaseURL: 'https://xross-db-test.firebase.com',
  projectId: 'xross-db-test',
  storageBucket: 'xross-db-test.appspot.com',
  messagingSenderId: '1072184289013',
  appId: '1:1072184289013:web:2f8439c3209d778fc487bc',
};

const STAGING_firebaseConfig = {
  apiKey: 'AIzaSyAQ9NEKSJsDILM9vsqeXVGDmtOjucOw4AA',
  authDomain: 'xross-db-staging-39c39.firebaseapp.com',
  databaseURL: 'https://xross-db-staging-39c39".firebase.com',
  projectId: 'xross-db-staging-39c39',
  storageBucket: 'xross-db-staging-39c39".appspot.com',
  messagingSenderId: '405770492037',
  appId: '1:405770492037:web:6183c1049f0e70647171ec',
};

// export const firebaseConfig =
//   XROSS_ENV === 'production' ? LIVE_firebaseConfig : DEV_firebaseConfig;

export const firebaseConfig =
  XROSS_ENV === 'production'
    ? LIVE_firebaseConfig
    : XROSS_ENV === 'development'
    ? DEV_firebaseConfig
    : STAGING_firebaseConfig;

export const envName =
  XROSS_ENV === 'production'
    ? '本番環境'
    : XROSS_ENV === 'development'
    ? 'テスト環境'
    : 'ステージング';
